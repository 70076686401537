var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"modal-content",attrs:{"id":"storeModal","title":"Selecciona la acción deseada","ok-variant":"outline-warning","ok-title":"Regresar","ok-only":"","no-close-on-backdrop":"","hide-footer":"","centered":"","body-class":"p-2"}},[_c('div',{staticClass:"payments-grid"},[(
        _vm.store.store_products_count > 0 &&
        _vm.establishment.establishment_type !== 'corporate_education'
      )?_c('div',{staticClass:"payment"},[_c('b-button',{staticClass:"h-100 w-100 payment-button",attrs:{"block":"","variant":"primary","size":"lg"},on:{"click":function($event){return _vm.handleStoreId(_vm.store)}}},[_c('i',{staticClass:"fas fa-cash-register payment-icon"}),_c('span',{staticClass:"mt-1"},[_vm._v("Caja registradora")])])],1):_vm._e(),(_vm.store.store_products_count > 0)?_c('div',{staticClass:"payment"},[_c('b-button',{staticClass:"h-100 w-100 payment-button",attrs:{"variant":"primary","block":"","to":{ name: 'sales-view', params: { id: _vm.store.id } }}},[_c('i',{staticClass:"fas fa-chart-line payment-icon"}),_c('span',{staticClass:"mt-1"},[_vm._v("Reporte de ventas")])])],1):_vm._e(),(_vm.store.store_products_count > 0)?_c('div',{staticClass:"payment"},[_c('b-button',{staticClass:"h-100 w-100 payment-button",attrs:{"variant":"primary","block":"","to":{ name: 'online-sales-view', params: { id: _vm.store.id } }}},[_c('i',{staticClass:"fas fa-list payment-icon"}),_c('span',{staticClass:"mt-1"},[_vm._v("Ventas en linea")])])],1):_vm._e(),_c('div',{staticClass:"payment"},[_c('b-button',{staticClass:"h-100 w-100 payment-button",attrs:{"variant":"primary","block":"","to":{ name: 'inventory_options', params: { id: _vm.store.id } }}},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"40"}}),_c('span',{staticClass:"mt-1"},[_vm._v(" Administrar inventario ")])],1)],1),(_vm.store.store_products_count === 0)?_c('div',[(_vm.store.store_products_count === 0)?_c('h4',{staticClass:"mt-2 mb-2 text-center text-success"},[_vm._v(" Nuevo ")]):_vm._e(),_c('p',{staticClass:"mt-1 text-muted h5"},[_vm._v(" 👆 Agrega productos o servicios al inventario para poder realizar ventas ")])]):_vm._e(),(
        _vm.store.store_products_count > 0 &&
        _vm.establishment.establishment_type !== 'corporate_education'
      )?_c('div',{staticClass:"payment"},[_c('b-button',{staticClass:"h-100 w-100 payment-button",attrs:{"variant":"primary","block":"","to":{
          name: 'product-categories-list',
          params: { from_store_id: _vm.store.id },
        }}},[_c('i',{staticClass:"fas fa-truck payment-icon"}),_c('span',{staticClass:"mt-1"},[_vm._v("Comprar inventario")])])],1):_vm._e()]),(
      _vm.store.store_products_count > 0 &&
      _vm.establishment.establishment_type !== 'corporate_education'
    )?_c('div',[_c('open-close-store',{staticClass:"mt-1",attrs:{"store":_vm.store}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }