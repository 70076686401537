<template>
  <b-modal
    id="storeModal"
    title="Selecciona la acción deseada"
    class="modal-content"
    ok-variant="outline-warning"
    ok-title="Regresar"
    ok-only
    no-close-on-backdrop
    hide-footer
    centered
    body-class="p-2"
  >
    <div class="payments-grid">
      <div
        v-if="
          store.store_products_count > 0 &&
          establishment.establishment_type !== 'corporate_education'
        "
        class="payment"
      >
        <b-button
          block
          variant="primary"
          size="lg"
          class="h-100 w-100 payment-button"
          @click="handleStoreId(store)"
        >
          <i class="fas fa-cash-register payment-icon" />
          <span class="mt-1">Caja registradora</span>
        </b-button>
      </div>

      <div v-if="store.store_products_count > 0" class="payment">
        <b-button
          variant="primary"
          block
          class="h-100 w-100 payment-button"
          :to="{ name: 'sales-view', params: { id: store.id } }"
        >
          <i class="fas fa-chart-line payment-icon" />
          <span class="mt-1">Reporte de ventas</span>
        </b-button>
      </div>

      <div v-if="store.store_products_count > 0" class="payment">
        <b-button
          variant="primary"
          block
          class="h-100 w-100 payment-button"
          :to="{ name: 'online-sales-view', params: { id: store.id } }"
        >
          <i class="fas fa-list payment-icon" />
          <span class="mt-1">Ventas en linea</span>
        </b-button>
      </div>

      <div class="payment">
        <b-button
          variant="primary"
          block
          class="h-100 w-100 payment-button"
          :to="{ name: 'inventory_options', params: { id: store.id } }"
        >
          <feather-icon icon="PackageIcon" size="40" />
          <span class="mt-1"> Administrar inventario </span>
        </b-button>
      </div>

      <div v-if="store.store_products_count === 0">
        <h4
          v-if="store.store_products_count === 0"
          class="mt-2 mb-2 text-center text-success"
        >
          Nuevo
        </h4>
        <p class="mt-1 text-muted h5">
          👆 Agrega productos o servicios al inventario para poder realizar
          ventas
        </p>
      </div>

      <div
        v-if="
          store.store_products_count > 0 &&
          establishment.establishment_type !== 'corporate_education'
        "
        class="payment"
      >
        <b-button
          variant="primary"
          block
          class="h-100 w-100 payment-button"
          :to="{
            name: 'product-categories-list',
            params: { from_store_id: store.id },
          }"
        >
          <i class="fas fa-truck payment-icon" />
          <span class="mt-1">Comprar inventario</span>
        </b-button>
      </div>
    </div>
    <div
      v-if="
        store.store_products_count > 0 &&
        establishment.establishment_type !== 'corporate_education'
      "
    >
      <open-close-store :store="store" class="mt-1" />
    </div>
  </b-modal>
</template>

<script>
import OpenCloseStore from "@/views/e-commerce/e-commerce-checkout/StoresButtonOpenCloseAdmins.vue";
export default {
  props: {
    store: {
      type: Object,
      required: true,
    },
    establishment: {
      type: Object,
      required: true,
    },
  },
  components: {
    OpenCloseStore,
  },
  data() {
    return {};
  },
  methods: {
    handleStoreId(store) {
      // localStorage.setItem
      let storeObj = JSON.stringify(store);
      localStorage.setItem("store", storeObj);
      this.$router.push({ name: 'POS', params: { store_id: store.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-btn-color {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}

.payments-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .payment {
    background-color: #0e1322;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.2s ease;

    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: 360px) {
      min-height: 130px;
    }
  }

  @media (min-width: 360px) {
    grid-template-columns: 1fr 1fr;
  }
}

.payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px !important;
  font-size: 1.1rem !important;

  @media (min-width: 480px) {
    font-size: 1.3rem !important;

    img {
      width: 100px;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.5rem !important;
    padding: 0 !important;
  }

  .payment-icon {
    font-size: 2rem;

    @media (min-width: 480px) {
      font-size: 2.5rem;
    }

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
}
</style>
